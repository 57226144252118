import React, { useLayoutEffect, useRef } from "react";
import babydiv from "../assets/img/babydiv.svg";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const Dev = () => {
  const babyDivRef = useRef(null);

  useLayoutEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: "#token",
        start: 800,
        end: "bottom top",
        scrub: true,
        markers: true,
      },
    });

    tl.fromTo(
      babyDivRef.current,
      { x: 0, y: 100 },
      { x: "900", y: "650", ease: "none" }
    );
  }, []);

  return (
    <section className="section-car">
      <div className="container-car">
    <div>
      <img ref={babyDivRef} src={babydiv} alt="babycar" className="babydiv" />
    </div>
    </div>
    </section>
  );
}; 