import React from "react";
import devface from "../assets/img/devface.svg";

export const Footer = () => {
  return (
    <section className="section-footer">
      <div className="container-footer">
          <div className="pepefooter">
            <div className="disclaimer">
              <img src={devface} alt="dev" className="devface" />
            <p>Disclaimer:<br/> Pepe The Dev, is a memecoin with no intrinsic value.<br/> Don’t risk money you are afraid of losing.<br/> The price may go up or it may go down.<br/> We are not responsible for the price of the token.</p>
            </div>
          </div>
      </div>
    </section>
  );
};