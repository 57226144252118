import React from "react";
import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from '../assets/img/logo.svg'
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';

export const Navbar = () => {

    const navRef = useRef();

    const showNavbar = () => {
        navRef.current.classList.toggle( 
            "responsive_nav" 
        );
    };   

        return (
			<section className="section">
				<div className="container">
            <header>
			<a href="/"><img src={logo} alt="logo"/></a>
			<nav ref={navRef}>
				<a href="/#token">Tokenomics</a>
				<a href="/#hbuy">How To Buy</a>
				<a href="/#chart">Chart</a>
				<button
					className="nav-btn nav-close-btn"
					onClick={showNavbar}>
					<FaTimes />
				</button>
				<div className="social-icon">
                <a href="https://www.dextools.io/app/en/pairs"><img src={navIcon1} alt="" /></a>
                <a href="https://web.telegram.org/a/"><img src={navIcon2} alt="" /></a>
                <a href="https://www.pezporn.com/media/images/1/weird-porn/weird-porn-12428.jpg" id="bnb"><img src={navIcon3} alt=""/></a>
              </div> 
			</nav>
			<button
				className="nav-btn"
				onClick={showNavbar}>
				<FaBars />
			</button>
		</header>
		</div>
		</section>
        );
    }