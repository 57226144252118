import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import pepedev from "../assets/vid/pepedev.webm";

export const Tokenomics = () => {
  useEffect(() => {
    AOS.init({
      offset: 250, 
      duration: 600, 
      easing: 'ease-in-out', 
    });
  }, []);

  const fullAddress = "0x7135cd0dc7c13dchtown0e00b9ce5ed14b84c588";

  const handleCopy = () => {
    navigator.clipboard.writeText(fullAddress).then(() => {
      alert("The Dev Is Working Hard!");
    }, () => {
      alert("Failed to copy address.");
    });
  };

  return (
    <section className="section-token">
      <div className="tokenomics" id="token">
      <div className="tokenx">           
              <h3 className="buyz "> Tokenomics</h3>
              <h3 className="buyz "> Tokenomics</h3>
              <h3 className="buyz "> Tokenomics</h3>
              <h3 className="buyz "> Tokenomics</h3>
         </div>  
        <div className="container">
               <div className="tax-container">
                <div className="tax" data-aos="fade-left">        
                Tax
                <p>0 tax <br /> on all your <br />transactions</p>
                </div>
                <video className="pepedev" autoPlay loop muted>
            <source src={pepedev} type="video/webm" />
            Your browser does not support the video tag.
          </video>
              </div>

              <div className="contract" data-aos="flip-down">
                Contract
                <p className="address">0xPepe4The20Dev69ETH</p>
                <button className="copy-button" onClick={handleCopy}>Copy Address</button>
              </div>  
            
              <div className="owner-container"> 
                <div className="infz">
                  Name: Baby Div<br />
                  Ticker: $BABYDIV<br />
                  Total Supply: 999,690,420
                </div>

                <div className="owner" data-aos="flip-left">
                     Ownership<p>
                     I don't Own 
                    <br />Something 
                    <br />That Is Yours</p>
                </div>

              </div>             
        </div>
      </div>
    </section>
  );
};
