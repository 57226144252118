import './App.css';
import { About } from './components/About';
import { Dev } from './components/Dev';
import { Footer } from './components/Footer';
import { Howbuy } from './components/Howbuy';
import { Navbar } from './components/Navbar';
import { Tokenomics } from './components/Tokenomics';


function App() {
  return (
    <div className="App">
      
      <Navbar />
      <About /> 
      <Dev />
      <Tokenomics />
      <Howbuy />     
      <Footer />
      
    </div>
  );
}

export default App;
