import React from "react";
import metamask from "../assets/img/metamask.svg";
import ethcoin from "../assets/img/ethcoin.svg";
import tokenbak1 from "../assets/img/tokenbak1.svg";

export const Howbuy = () => {

  return (
      <section className="section-hb" id="hbuy">
        <div className="buybuy">        
              <h3 className="buyz "> HOWTOBUY</h3>
              <h3 className="buyz "> HOWTOBUY</h3>
              <h3 className="buyz "> HOWTOBUY</h3>
              <h3 className="buyz "> HOWTOBUY</h3>
         </div>

        <div className="container-hb">
          <div className="backdev">
            <img src={tokenbak1} alt="tokenbak" className="tokenbak" />
          </div>

          <div className="howbuy-container">
              <img src={ethcoin} alt="meta" type="svg" className="ethcoin" /> 
              <p className="ethplug">SEND ALL YOUR ETH<br/> TO METAMASK TO<br/> BECOME FILTHY<br/> RICH!!!!!!!!!</p>             
            </div>

            <div className="wallet">
              <p>BUY ALL THE DEVS TOKEN SUPPLY<br /> TO BECOME<br/> MORE RICHER!!!!!!!!!!!!</p>               
              <img src={metamask} alt="meta" type="svg" className="metamask"/> 
                          
            </div>
            

            <div className="uniswap-container" id="chart">
              <div className="uniswap">
                <iframe src="https://app.uniswap.org/#/swap" title="Uniswap Interface" className="uniswap-iframe"/>
                <iframe src="https://dexscreener.com/bsc/0xb125aa15Ad943D96e813E4A06d0c34716F897e26?embed=1&theme=dark" title="Dexscreener Interface" className="dex"/>
              </div>
            </div>
          </div>
      </section>
  );
};
